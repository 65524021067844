<template>
  <div class="container" v-if="studentClasses">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ title }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading :content="title"></Heading>
    <hr />
    <div>
      <el-form label-width="80px">
        <el-form-item :label="$t('grading.class')">
          <el-select
            v-model="classIndex"
            placeholder="Select Class"
            filterable
            class="select-test"
          >
            <el-option
              v-for="(test, index) in studentClasses"
              :key="test.id"
              :value="test.id"
              :label="test.title"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="classIndex">
          <el-form-item label="">
            <router-link
              :to="{
                name: 'ClassMaterials',
                params: { classId: classIndex },
                query: { ...$route.query }
              }"
            >
              <el-button size="" type="primary" style="width: 100%;">
                <i class="fa fa-play"></i>
                查看練習
              </el-button>
            </router-link>
          </el-form-item>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { instant } from "@ivy-way/material";
import _ from "lodash";
import Breadcrumb from "@/components/Breadcrumb";
import Tests from "@/apis/tests.js";
import Materials from "@/apis/materials";

export default {
  metaInfo() {
    return {
      title: `${this.title} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      title: "單元練習",
      classes: [],
      classIndex: null,
      getTest: true,
      tests: [],
      testIndex: null,
      form: {
        exam_id: null,
        user_exam_id: null,
        type: null
      },
      studentClasses: null,
      studentUserExams: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {
    this.getUnitExams();
  },

  methods: {
    async getUnitExams() {
      const res = await Materials.getClassesByMaterialSlug({
        slug: this.$route.query.unit_slug
      });
      const studentClasses = res.session_classes;

      if (studentClasses.length === 1) {
        let vm = this;
        this.$router.push({
          name: "ClassMaterials",
          params: { classId: studentClasses[0].id },
          query: {
            ...vm.$route.query
          }
        });
        this.studentClasses = studentClasses;
      } else {
        this.studentClasses = studentClasses;
      }
    }
  }
};
</script>

<style scoped>
.select-test {
  width: 100%;
}
</style>
