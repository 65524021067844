import ApiService from "@/common/api.service";

export default {
  getSubjectsList(sessionClass) {
    return ApiService.query(`session-class/${sessionClass}/material-subjects`);
  },
  getSubjects(sessionClass) {
    return ApiService.query(`session-class/${sessionClass}/materials`);
  },
  createSubject(sessionClass, data) {
    return ApiService.post(`session-class/${sessionClass}/material-subjects`, data);
  },
  updateSubject(subjectId, data) {
    return ApiService.patch(`material-subjects/${subjectId}`, data);
  },
  removeSubject(subjectId) {
    return ApiService.delete(`material-subjects/${subjectId}`);
  },
  removeSubjectAll(subjectId) {
    return ApiService.delete(`material-subjects/${subjectId}/force-delete`);
  },
  createMaterial(data) {
    return ApiService.post("materials", data);
  },
  updateMaterial(materialId, data) {
    return ApiService.patch(`materials/${materialId}`, data);
  },
  removeMaterial(materialId) {
    return ApiService.delete(`materials/${materialId}`);
  },
  resetOrder(data){
    return ApiService.patch("materials/batch-order", data);
  },
  // addTest(data){
  //   return ApiService.post("materials/test", data);
  // },
  getClassesByMaterialSlug(data) {
    return ApiService.query("classes-by-material-slug", data);
  },
  removeTest(id) {
    return ApiService.delete(`material-tests/${id}`);
  },
  testOrder(materialId, data) {
    return ApiService.patch(`materials/${materialId}/test-order`, data);
  },
  addTest(materialId, data) {
    return ApiService.post(`materials/${materialId}/tests`, data);
  },
  removeFile(id) {
    return ApiService.delete(`material-files/${id}`);
  },
  filesOrder(materialId, data){
    return ApiService.patch(`materials/${materialId}/file-order`, data);
  },
  addFile(materialId, data) {
    return ApiService.post(`materials/${materialId}/files`, data);
  }
};
